import {getEventTitle, isDateTbd} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {BrowserOnly} from '../../../commons/components/browser-only'
import {useEventDateInformation} from '../../../commons/hooks/dates'
import {EVENT_SUMMARY, EVENT_TITLE} from '../../config/data-hooks'
import {DetailedEvent} from '../../types/state'
import {EventText} from '../styled-components/event-text'
import s from './event-summary.scss'
import {EventSummaryProps} from '.'

export const EventSummary = ({event, mobile, t}: EventSummaryProps) => (
  <div className={classNames(s.root, {[s.mobile]: mobile})} data-hook={EVENT_SUMMARY}>
    {mobile ? <MobileDate event={event} t={t} /> : <Date event={event} />}
    <div className={s.name}>
      <EventText data-hook={EVENT_TITLE} extraClass={s.nameText}>
        {getEventTitle(event.event)}
      </EventText>
    </div>
  </div>
)

const Date = ({event}: {event: DetailedEvent}) => {
  const {shortStartDate} = useEventDateInformation(event.event.id)
  return (
    <div className={s.date}>
      <EventText>
        <BrowserOnly>{shortStartDate}</BrowserOnly>
      </EventText>
    </div>
  )
}

const MobileDate = ({event, t}: {event: DetailedEvent; t: Function}) => (
  <div className={s.mobileDateContainer}>
    {isDateTbd(event.event) ? <MobileTbdDate t={t} /> : <MobileScheduledDate event={event} />}
  </div>
)

const MobileTbdDate = ({t}) => (
  <div className={s.tbdDate}>
    <EventText extraClass={s.tbd}>{t('events.tbd-time')}</EventText>
  </div>
)

const MobileScheduledDate = ({event}: {event: DetailedEvent}) => {
  const {day, month} = useEventDateInformation(event.event.id)

  return (
    <BrowserOnly>
      <div className={s.mobileDate}>
        <EventText extraClass={s.day}>{day}</EventText>
        <EventText extraClass={s.month} faded>
          {month}
        </EventText>
      </div>
    </BrowserOnly>
  )
}
